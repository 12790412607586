<template>
  <div class="clients-create">
    <ui-wizard
      :title="$t('clients.create.title')"
      :breadcrumbs="breadcrumbs"
      :is-saving="isSaving"
      @back="onBack"
      @on-complete="onComplete"
      @update:startIndex="updateIndex"
    >
      <ui-wizard-step :title="$t('clients.create.step.client')" :before-change="checkClient">
        <ui-input
          class="clients-create__input"
          v-model.trim="name"
          :label="$t('clients.create.name')"
          id="client-name"
          :is-required="true"
          :error="$v.name.$error"
        >
          <template v-if="$v.name.$error && !$v.name.required">
            {{ $t('errors.required') }}
          </template>
        </ui-input>
        <ui-input
          class="clients-create__input"
          v-model.trim="id"
          :label="$t('clients.create.id')"
          id="client-id"
          :is-required="true"
          :error="$v.id.$error"
        >
          <template v-if="$v.id.$error && !$v.id.required">
            {{ $t('errors.required') }}
          </template>
          <template v-if="$v.id.$error && !$v.id.slug">
            {{ $t('errors.slug') }}
          </template>
        </ui-input>
        <ui-input
          class="clients-create__input"
          v-model.trim="gatoreviewsId"
          :label="$t('clients.create.gatoreviewsId')"
          id="client-gatoreviewsId"
          :error="$v.gatoreviewsId.$error"
        >
          <template v-if="$v.gatoreviewsId.$error && !$v.gatoreviewsId.required">
            {{ $t('errors.required') }}
          </template>
          <template v-if="$v.gatoreviewsId.$error && !$v.gatoreviewsId.slug">
            {{ $t('errors.slug') }}
          </template>
        </ui-input>
        <ui-dropdown
          class="clients-create__input"
          v-model="defaultLocale"
          :options="defaultLocaleOptions"
          label="name"
          track-by="id"
          :placeholder="$t('clients.create.locale.placeholder')"
          :dropdown-label="$t('clients.create.locale.defaultLocale')"
          :show-label="true"
          :error="$v.defaultLocale.$error"
          @input="refreshOtherLocales"
          :is-required="true"
        >
          <template v-slot:helper v-if="$v.defaultLocale.$error && !$v.defaultLocale.required">
            {{ $t('errors.required') }}
          </template>
        </ui-dropdown>
        <ui-tag
          v-if="otherLocalesOptions.length > 0"
          v-model="otherLocales"
          :options="otherLocalesOptions"
          label="name"
          track-by="id"
          :placeholder="$t('clients.create.locale.placeholder')"
          :tag-label="$t('clients.create.locale.otherLocales')"
          :show-label="true"
          @input="setLocales"
        />
      </ui-wizard-step>
      <ui-wizard-step :title="$t('clients.create.step.summary')">
        <div class="clients-create__grid">
          <screen-block
            :title="$t('clients.create.label.client')"
            icon="location"
            :has-data="name !== ''"
            :data="name"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('clients.create.label.defaultLanguage')"
            icon="language"
            :has-data="defaultLocale !== null"
            :data="defaultLocale ? defaultLocale.name : ''"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('clients.create.label.gatoreviewsId')"
            icon="article"
            :has-data="!!gatoreviewsId"
            :data="gatoreviewsId"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          />
          <screen-block
            :title="$t('clients.create.label.otherLanguage')"
            icon="language"
            :has-data="otherLocales.length > 0"
            ratio="1-2"
            :is-loading="false"
            :is-editable="false"
          >
            <template v-slot:data>
              <span v-for="(locale, idx) in otherLocales" :key="`locales-${idx}`">
                {{ locale.name }}<template v-if="idx < otherLocales.length - 1">, </template>
              </span>
            </template>
          </screen-block>
        </div>
      </ui-wizard-step>
    </ui-wizard>
  </div>
</template>

<script>
import UiWizard from '@/components/UI/Wizard/Wizard.vue'
import UiWizardStep from '@/components/UI/Wizard/Step.vue'
import UiDropdown from '@/components/UI/Dropdown.vue'
import UiInput from '@/components/UI/Input.vue'
import UiTag from '@/components/UI/Tag.vue'
import ScreenBlock from '@/components/Screen/Block.vue'
import { notif } from '@/mixins/notification.mixin'
import { mapActions, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { slug } from '@/validators/slug.validator'

export default {
  name: 'ClientsCreate',
  components: {
    UiWizard,
    UiWizardStep,
    UiDropdown,
    UiInput,
    UiTag,
    ScreenBlock,
  },
  mixins: [notif],
  data() {
    return {
      name: '',
      id: '',
      gatoreviewsId: '',
      defaultLocale: null,
      otherLocales: [],
      isSaving: false,
      wizardIndex: 0,
    }
  },
  computed: {
    ...mapState({
      locales: state => state.user.locales,
    }),
    breadcrumbs() {
      return [
        {
          label: this.$t('clients.list.breadcrumb'),
          route: {
            name: 'Clients',
          },
        },
        {
          label: this.$t('clients.create.breadcrumb'),
          route: {
            name: 'ClientCreate',
          },
        },
      ]
    },
    defaultLocaleOptions() {
      return this.locales
    },
    otherLocalesOptions() {
      if (this.defaultLocale) {
        return this.defaultLocaleOptions.filter(locale => locale.id !== this.defaultLocale.id)
      }
      return []
    },
  },
  methods: {
    ...mapActions({
      createClient: 'client/createClient',
      updateCurrentClient: 'user/updateCurrentClient',
    }),
    onBack() {
      this.$router.push({ name: 'Clients' })
    },
    setLocales(locales) {
      this.otherLocales = locales
    },
    refreshOtherLocales() {
      this.otherLocales = this.otherLocales.filter(locale => locale.id !== this.defaultLocale.id)
    },
    onComplete() {
      this.isSaving = true
      this.createClient({
        id: this.id,
        name: this.name,
        defaultLocaleId: this.defaultLocale.id,
        gatoreviewsId: this.gatoreviewsId,
        locales: this.otherLocales.map(locale => locale.id),
      })
        .then(client => {
          this.notificationSuccess('client', client.name, 'create')
          this.$router.push({ name: 'Client', params: { id: client.id } })
          this.isSaving = false
        })
        .catch(() => {
          this.isSaving = false
          this.notificationError()
        })
    },
    updateIndex(index) {
      this.wizardIndex = index
    },
    checkClient() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$v.$reset()
        return true
      }
      return false
    },
  },
  validations() {
    return {
      name: {
        required,
      },
      id: {
        required,
        slug,
      },
      gatoreviewsId: {},
      defaultLocale: {
        required,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.clients-create {
  &__input {
    margin-bottom: $gutter-mobile;

    @media (min-width: $screen-sm) {
      margin-bottom: $gutter-tablet;
    }
  }

  &__grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: (-$gutter-mobile / 2);

    @media (min-width: $screen-sm) {
      margin: (-$gutter-tablet / 2);
    }

    @media (min-width: $screen-xl) {
      margin: (-$gutter-desktop / 2);
    }
  }
}
</style>
